import { computed, type Ref } from 'vue';
import {
  eachYearOfInterval,
  eachMonthOfInterval,
  eachDayOfInterval,
  getMonth,
  getDate,
  getYear,
  parse,
  format,
  isBefore,
  isAfter,
  isValid,
  setDate,
  setMonth
} from 'date-fns';

export const MIN_DATE_DEFAULT = '01-01-1900';
export const MAX_DATE_DEFAULT = '31-12-2100';
const DEFAULT_DATE_FORMAT = 'dd-MM-yyyy';

export function parseDate(dateString?: string, format = DEFAULT_DATE_FORMAT) {
  if (!dateString) return '';
  return parse(dateString, format, new Date());
}

export function isValidDate(dateString?: string) {
  const date = parseDate(dateString);
  return isValid(date);
}

export function isValidDayAndMonth(day: number, month: number) {
  let date = new Date(2000, 0, 1);
  date = setMonth(date, month - 1); 
  date = setDate(date, day);
  return isValid(date) && date.getDate() === day && date.getMonth() === month - 1;
}

export function composeDate(dateString?: string, formatStr = DEFAULT_DATE_FORMAT) {
  if (!dateString) return { date: '', day: '', month: '', year: '' };
  const date = new Date(parseDate(dateString, formatStr));
  return {
    date,
    day: format(date, 'dd'),
    month: format(date, 'MM'),
    year: format(date, 'yyyy')
  };
}

function getIntervalObj(options?: { minDate?: string; maxDate?: string; format?: string }) {
  const { minDate, maxDate, format = DEFAULT_DATE_FORMAT } = options || {};
  return {
    start: new Date(parseDate(minDate || MIN_DATE_DEFAULT, format)),
    end: new Date(parseDate(maxDate || MAX_DATE_DEFAULT, format))
  };
}

const sortAsc = (a: any, b: any) => a - b;

export function fillYearOptions(options?: { minDate?: string; maxDate?: string; format?: string }) {
  const { minDate, maxDate, format } = options || {};
  const years = eachYearOfInterval(getIntervalObj({ minDate, maxDate, format }));
  const distinctYears = new Set();
  years.forEach((date) => distinctYears.add(getYear(date)));
  return Array.from(distinctYears)
    .sort(sortAsc)
    .map((year: any) => {
      const yearText = String(year).padStart(2, '0');
      return { label: yearText, value: yearText };
    });
}

export function fillMonthOptions(options?: { minDate?: string; maxDate?: string; format?: string }) {
  const { minDate, maxDate, format } = options || {};
  const distinctMonthIndexes = new Set();
  const months = eachMonthOfInterval(getIntervalObj({ minDate, maxDate, format }));
  months.forEach((date) => distinctMonthIndexes.add(getMonth(date)));
  return Array.from(distinctMonthIndexes)
    .sort(sortAsc)
    .map((monthIndex: any) => {
      const monthIndexText = String(++monthIndex).padStart(2, '0');
      return { label: monthIndexText, value: monthIndexText };
    });
}

export function fillDayOptions(options?: { minDate?: string; maxDate?: string; format?: string; dayCount?: number }) {
  const { minDate, maxDate, format = DEFAULT_DATE_FORMAT, dayCount = 31 } = options || {};
  const distinctDays = new Set();
  const months = eachDayOfInterval(getIntervalObj({ minDate, maxDate, format }));
  months.forEach((date) => distinctDays.add(getDate(date)));
  return Array.from(distinctDays)
    .sort(sortAsc)
    .map((dayNo: any) => {
      const dayText = String(dayNo).padStart(2, '0');
      return { label: dayText, value: dayText };
    })
    .filter((dayItem) => +dayItem.value <= dayCount);
}

export function checkIsBefore(dateStr?: string, dateStrToCompare?: string) {
  if (!dateStr || !dateStrToCompare) return false;
  const date = parseDate(dateStr);
  const dateToCompare = parseDate(dateStrToCompare);
  return isBefore(new Date(date), new Date(dateToCompare));
}

export function checkIsAfter(dateStr?: string, dateStrToCompare?: string) {
  if (!dateStr || !dateStrToCompare) return false;
  const date = parseDate(dateStr);
  const dateToCompare = parseDate(dateStrToCompare);
  return isAfter(new Date(date), new Date(dateToCompare));
}

export function useDateSelectOptionsWithInterval(options?: { minDate?: Ref<string>; maxDate?: Ref<string> }) {
  const { minDate, maxDate } = options || {};
  const dayOptions = computed(() => fillDayOptions({ minDate: minDate?.value, maxDate: maxDate?.value }));
  const monthOptions = computed(() => fillMonthOptions({ minDate: minDate?.value, maxDate: maxDate?.value }));
  const yearOptions = computed(() => fillYearOptions({ minDate: minDate?.value, maxDate: maxDate?.value }));

  return {
    dayOptions,
    monthOptions,
    yearOptions
  };
}
